<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-card>
            <v-card-title>
              Orders
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                @change="searchOrders()"
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :mobile-breakpoint="0"
              :single-expand="true"
              :headers="datatable.headers"
              :items="datatable.desserts"
              :server-items-length="datatable.total"
              :loading="datatable.isLoading"
              :options.sync="options"
              :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 20, 50, 100]
              }"
              item-key="id"
              show-expand
            >
              <template v-slot:item.customer_name="{ item }">
                {{ item.shipping_address && item.shipping_address.full_name }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                  class="ma-1"
                  :color="getStatusColor(item.status)"
                  text-color="white"
                >
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:item.profit="{ item }">
                {{ parseInt(item.profit) + "%" }}
              </template>
              <template v-slot:item.paid_date="{ item }">
                {{ convertDate(item.paid_date) }}
              </template>
              <template v-slot:item.order_date="{ item }">
                {{ convertDate(item.order_date) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="syncOrderToEdara(item)" v-b-tooltip.hover title="Create or update invoice">
                  mdi-sync
                </v-icon>
                <v-icon small class="mr-2" @click="syncOrderToEdara(item, true)" v-b-tooltip.hover title="Create new invoice">
                  mdi-sync-alert
                </v-icon>
                <v-icon small class="mr-2" @click="pullFromPCDStore(item)" v-b-tooltip.hover title="Get order from store">
                  mdi-reload
                </v-icon>
                <v-icon small class="mr-2" @click="deleteOrder(item)" v-b-tooltip.hover title="Delete order from table">
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click="markOrderAsSent(item)" v-b-tooltip.hover title="Mark order as sent">
                  mdi-check
                </v-icon>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-alert type="error" v-if="item.error">
                    {{ item.error }}
                  </v-alert>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :mobile-breakpoint="0"
                        :headers="lines_datatable.headers"
                        :items="item.line_items"
                        item-key="id"
                      >
                        <template v-slot:item.price="{ item }">
                          {{ parseInt(item.price).toFixed(2) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<style scoped lang="scss">
@import "../../../../node_modules/vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ApiService from "../../../core/services/api.service";
import Vue from "vue";
import _ from "lodash";
import moment from "moment-timezone";

const ignoreWarnMessage = `The .native modifier for v-on is only valid on components but it was used on <div>.`;
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function(msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

export default {
  name: "Orders",
  data() {
    return {
      lines_datatable: {
        headers: [
          {
            text: "Product",
            sortable: false,
            value: "title",
            width: "27%"
          },
          { text: "SKU", value: "sku", width: "12%", sortable: false },
          {
            text: "Parent",
            value: "parent_sku",
            width: "12%",
            sortable: false
          },
          { text: "Price", value: "price", width: "8%", sortable: false },
          {
            text: "Avg Cost",
            value: "product.purchase_price",
            width: "8%",
            sortable: false
          },
          {
            text: "Collected",
            value: "is_collected",
            width: "8%",
            sortable: false
          },
          {
            text: "Quantity",
            value: "quantity",
            width: "8%",
            sortable: false
          },
          {
            text: "Virtual",
            value: "virtual",
            width: "8%",
            sortable: false
          }
        ]
      },
      options: {},
      datatable: {
        total: 0,
        search: "",
        isLoading: false,
        headers: [
          {
            text: "Customer",
            align: "left",
            sortable: false,
            value: "customer_name",
            width: "12%"
          },
          { text: "Store Id", value: "channel_order_id", width: "10%" },
          { text: "Edara Id", value: "edara_order_id", width: "12%" },
          { text: "Status", value: "status", width: "4%" },
          { text: "Store Stat.", value: "channel_status", width: "11%" },
          { text: "Total", value: "total", width: "8%" },
          { text: "Profit", value: "profit", width: "7%", sortable: false },
          { text: "Paid Date", value: "paid_date", width: "10%" },
          { text: "Order Date", value: "order_date", width: "11%" },
          { text: "Actions", value: "actions", width: "14%", sortable: false },
          { text: "", value: "data-table-expand", width: "1%", sortable: false }
        ],
        desserts: []
      }
    };
  },
  components: {},
  watch: {
    options: {
      handler() {
        this.loadOrders();
      },
      deep: true
    }
  },
  methods: {
    syncOrderToEdara(item, syncToNewInvoice) {
      ApiService.get(`orders/${item.id}/edara-sync`, {
        sync_to_new_invoice: syncToNewInvoice
      })
        .then(({ data }) => {
          this.loadOrders(this.datatable.page);

          this.makeToast(
            data.status !== "failed" ? "success" : "danger",
            data.message
              ? data.message
              : "Check order errors and fix it then resend order"
          );
        })
        .catch(() => {
          this.makeToast("danger", "Error while syncing order to edara");
        });
    },
    pullFromPCDStore(item) {
      ApiService.get(`orders/${item.id}/pull`)
        .then((/*{ data }*/) => {
          this.loadOrders(this.datatable.page);
          this.makeToast("success", "Order pulled from store successfully!");
        })
        .catch(() => {
          this.makeToast("danger", "Error while pulling order from store");
        });
    },
    deleteOrder(item) {
      this.$confirm("Do you really want to delete this order?").then(res => {
        if (res) {
          ApiService.delete(`orders/${item.id}`)
            .then((/*{ data }*/) => {
              this.loadOrders(this.datatable.page);
              this.makeToast("success", "Order deleted successfully!");
            })
            .catch(() => {
              this.makeToast("danger", "Error while deleting order!");
            });
        }
      });
    },
    markOrderAsSent(order) {
      ApiService.put(`orders/${order.id}/status`, {
        status: "sent"
      })
        .then((/*{ data }*/) => {
          this.loadOrders(this.datatable.page);
          this.makeToast("success", "Order Marked as sent successfully!");
        })
        .catch(() => {
          this.makeToast("danger", "Error while marking order sent!");
        });
    },
    loadOrders() {
      this.datatable.isLoading = true;
      let sortBy = "";
      let sortDesc = false;
      if (this.options.sortBy.length == 0) {
        sortBy = "order_date";
        sortDesc = true;
      } else {
        sortBy = this.options.sortBy[0];
        sortDesc = this.options.sortDesc[0];
      }

      ApiService.get("orders", {
        per_page: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: sortBy,
        sort: sortDesc ? "DESC" : "ASC",
        search: this.search
      })
        .then(({ data }) => {
          this.datatable.total = data.total;
          this.datatable.desserts = data.data;
        })
        .finally(() => {
          this.datatable.isLoading = false;
        });
    },
    searchOrders() {
      this.options.page = 1;
      this.loadOrders();
    },
    getStatusColor(status) {
      switch (status) {
        case "failed":
          return "red";
        case "sent":
        case "completed":
          return "green";
        case "pending":
          return "orange";
        case "missing-items":
          return "primary";
      }
      return "secondary";
    },
    makeToast(variant = null, message) {
      this.$bvToast.toast(message, {
        title: `Orders`,
        variant: variant,
        solid: true
      });
    },
    convertDate(date) {
      return moment(date)
        .add(3, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    }
  },
  created() {
    this.loadOrders = _.debounce(this.loadOrders, 300);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Orders", route: "orders" }
    ]);
    this.loadOrders();
  }
};
</script>
